import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsAndConditions.css';
import logo from './Half-Eaten-Donut.png'; // Update the logo path

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="terms-container">
      <header className="terms-header">
        <img src={logo} alt="Company Logo" className="terms-logo" onClick={handleLogoClick} />
      </header>

      <main className="terms-content">
        <h1>Terms and Conditions</h1>
        <p><strong>Last Updated: 9/26/2024</strong></p>
        <p>
          These Terms and Conditions of Use ("Terms") govern your use of the marketing data software ("Software") and services provided by Half Eaten Donut ("us"). By accessing or using our Software, you agree to comply with these Terms. If you do not agree to these Terms, you must not use our Software.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Software, you agree to be bound by these Terms, as well as our Privacy Policy, which is incorporated by reference into these Terms. We may update these Terms from time to time, and any changes will take effect immediately upon posting. It is your responsibility to review the Terms periodically.
        </p>

        <h2>2. Eligibility</h2>
        <p>
          You must be at least 18 years old and have the legal capacity to enter into a binding contract to use the Software. By using our Software, you represent and warrant that you meet these eligibility requirements.
        </p>

        <h2>3. Account Registration</h2>
        <p>
          To access certain features of the Software, you may be required to create an account. You agree to provide accurate, current, and complete information during registration and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </p>

        <h2>4. Use of the Software</h2>
        <p>You agree not to:</p>
        <ul>
          <li>Use the Software in any manner that infringes upon or violates the rights of any third party.</li>
          <li>Engage in unauthorized access, tampering, or interference with the Software or its related systems.</li>
          <li>Reverse-engineer, decompile, disassemble, or otherwise attempt to discover the source code of the Software.</li>
          <li>Use the Software to distribute unsolicited or unauthorized marketing, spam, or other commercial messages.</li>
        </ul>

        <h2>5. Intellectual Property</h2>
        <p>
          The Software and all related intellectual property, including but not limited to copyrights, trademarks, and patents, are owned or licensed by Half Eaten Donut. You are granted a limited, non-exclusive, non-transferable, and revocable license to use the Software for its intended purpose.
        </p>

        <h2>6. Fees and Payment</h2>
        <p>
          Access to certain features of the Software may require payment of fees. If applicable, you agree to pay all fees in accordance with the pricing and payment terms provided at the time of purchase.
        </p>

        <h2>7. User Content</h2>
        <p>
          If you upload or submit any content through the Software ("User Content"), you retain ownership of your User Content. However, by submitting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, distribute, and display the content for the purpose of providing the Software.
        </p>

        <h2>8. Data Privacy</h2>
        <p>
          Your use of the Software is subject to our Privacy Policy, which explains how we collect, use, and share your personal information.
        </p>

        <h2>9. Warranties and Disclaimers</h2>
        <p>
          The Software is provided on an "as is" and "as available" basis without any warranties, express or implied.
        </p>

        <h2>10. Limitation of Liability</h2>
        <p>
          To the extent permitted by law, Half Eaten Donut will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Software.
        </p>

        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Half Eaten Donut and its officers, directors, employees, and agents from any claims, liabilities, damages, or expenses arising from your use of the Software.
        </p>

        <h2>12. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to the Software at any time for any reason.
        </p>

        <h2>13. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of [Insert State], Australia.
        </p>

        <h2>14. Severability</h2>
        <p>
          If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
        </p>

        <h2>15. Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and Half Eaten Donut regarding your use of the Software.
        </p>

        <h2>16. Contact Information</h2>
        <p>
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <p>
          <strong>Half Eaten Donut</strong><br />
          44 Chelsea St, Redfern NSW 2016, Australia<br />
          support@halfeatendonut.com
        </p>
      </main>
    </div>
  );
};

export default TermsAndConditions;
