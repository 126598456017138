// // // import React, { useState, useEffect } from 'react';
// // // import { useLocation, useNavigate } from 'react-router-dom';
// // // import { Pie } from 'react-chartjs-2';
// // // import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
// // // import './EventDetails.css';
// // // import axios from 'axios';


// // // ChartJS.register(Title, Tooltip, Legend, ArcElement);

// // // const EventDetails = () => {
// // //   const location = useLocation();
// // //   const navigate = useNavigate();
// // //   const { eventName } = location.state || {};

// // //   const [offlinePlatforms, setOfflinePlatforms] = useState([]);
// // //   const [onlinePlatforms, setOnlinePlatforms] = useState([]);
// // //   const [metrics, setMetrics] = useState({
// // //     overallRevenue: '',
// // //     averageOrderValue: '',
// // //     totalRegistrations: ''
// // //   });

// // //   useEffect(() => {
// // //     if (eventName) {
// // //       fetchPlatforms(eventName);
// // //     }
// // //   }, [eventName]);

// // //   const fetchPlatforms = async (eventName) => {
// // //     try {
// // //       const response = await axios.get(`http://localhost:5000/event_investment/${eventName}`);
// // //       setOfflinePlatforms(response.data.offlineInvestments || {});
// // //       setOnlinePlatforms(response.data.onlineInvestments || {});
// // //     } catch (error) {
// // //       console.error('Error fetching investments:', error);
// // //     }
// // //   };

// // //   const getPieData = (platforms, title) => {
// // //     const labels = platforms;
// // //     const data = platforms.map(platform => Math.random() * 100); // Replace with actual data if available

// // //     return {
// // //       labels,
// // //       datasets: [{
// // //         label: title,
// // //         data,
// // //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
// // //       }]
// // //     };
// // //   };

// // //   const handlePlanNextEvent = () => {
// // //     navigate('/');
// // //   };

// // //   const handleMetricsChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setMetrics(prevMetrics => ({
// // //       ...prevMetrics,
// // //       [name]: value
// // //     }));
// // //   };

// // //   const handleSubmitMetrics = () => {
// // //     // Here you would typically send the metrics to your backend
// // //     navigate('/')
// // //     // You can add logic here to navigate to a new page or update the UI
// // //   };

// // //   return (
// // //     <div className="event-details">
// // //       <h1>Event Details: {eventName}</h1>
      
// // //       <h2>Budget Allocation Existing Campaign</h2>
// // //       <div className="chart-wrapper">
// // //         <div className="chart-container">
// // //           <h3>Offline Platforms</h3>
// // //           <Pie data={getPieData(offlinePlatforms, 'Offline Platforms')} />
// // //         </div>

// // //         <div className="chart-container">
// // //           <h3>Online Platforms</h3>
// // //           <Pie data={getPieData(onlinePlatforms, 'Online Platforms')} />
// // //         </div>
// // //       </div>

// // //       <div className="metrics-overview">
// // //         <h2>Metrics Overview</h2>
// // //         <p>Please fill them in here to receive a detailed strategy</p>
// // //         <div className="metrics-inputs">
// // //           <input
// // //             type="text"
// // //             name="overallRevenue"
// // //             value={metrics.overallRevenue}
// // //             onChange={handleMetricsChange}
// // //             placeholder="Overall Revenue *"
// // //           />
// // //           <input
// // //             type="text"
// // //             name="averageOrderValue"
// // //             value={metrics.averageOrderValue}
// // //             onChange={handleMetricsChange}
// // //             placeholder="Average Order Value *"
// // //           />
// // //           <input
// // //             type="text"
// // //             name="totalRegistrations"
// // //             value={metrics.totalRegistrations}
// // //             onChange={handleMetricsChange}
// // //             placeholder="Total Registrations *"
// // //           />
// // //         </div>
// // //         <div className="button-container">
// // //           <button className="back-button" onClick={() => navigate(-1)}>Back</button>
// // //           <button className="plan-next-event-button" onClick={handleSubmitMetrics}>
// // //             Plan Your Next Event
// // //           </button>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default EventDetails;

// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import { Pie } from 'react-chartjs-2';
// // import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
// // import './EventDetails.css';

// // ChartJS.register(Title, Tooltip, Legend, ArcElement);

// // const EventDetails = () => {
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   const { eventName } = location.state || {};
// //   const [offlineInvestments, setOfflineInvestments] = useState({});
// //   const [onlineInvestments, setOnlineInvestments] = useState({});
// //   const [metrics, setMetrics] = useState({
// //     overallRevenue: '',
// //     averageOrderValue: '',
// //     totalRegistrations: ''
// //   });

// //   useEffect(() => {
// //     if (eventName) {
// //       fetchInvestments(eventName);
// //     }
// //   }, [eventName]);

// //   const fetchInvestments = async (eventName) => {
// //     try {
// //       const response = await axios.get(`http://localhost:5000/event_investment/${eventName}`);
// //       setOfflineInvestments(response.data.offlineInvestments || {});
// //       setOnlineInvestments(response.data.onlineInvestments || {});
// //     } catch (error) {
// //       console.error('Error fetching investments:', error);
// //     }
// //   };

// //   const getPieData = (investments, title) => {
// //     const labels = Object.keys(investments);
// //     const data = Object.values(investments);
// //     return {
// //       labels,
// //       datasets: [{
// //         label: title,
// //         data,
// //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
// //       }]
// //     };
// //   };

// //   const handleMetricsChange = (e) => {
// //     const { name, value } = e.target;
// //     setMetrics(prevMetrics => ({ ...prevMetrics, [name]: value }));
// //   };

// //   const handleSubmitMetrics = () => {
// //     // Here you would typically send the metrics to your backend
// //     navigate('/');
// //   };

// //   return (
// //     <div className="event-details">
// //       <h1>Event Details: {eventName}</h1>
// //       <h2>Budget Allocation Existing Campaign</h2>
// //       <div className="chart-wrapper">
// //         {/* <div className="chart-container">
// //           <h3>Offline Platforms</h3>
// //           <Pie data={getPieData(offlineInvestments, 'Offline Platforms')} />
// //         </div> */}
// //         <div className="chart-container">
// //           <h3>Online Platforms</h3>
// //           <Pie data={getPieData(onlineInvestments, 'Online Platforms')} />
// //         </div>
// //       </div>
// //       <div className="metrics-overview">
// //         <h2>Metrics Overview</h2>
// //         <p>Please fill them in here to receive a detailed strategy</p>
// //         <div className="metrics-inputs">
// //           <input
// //             type="text"
// //             name="overallRevenue"
// //             value={metrics.overallRevenue}
// //             onChange={handleMetricsChange}
// //             placeholder="Overall Revenue *"
// //           />
// //           <input
// //             type="text"
// //             name="averageOrderValue"
// //             value={metrics.averageOrderValue}
// //             onChange={handleMetricsChange}
// //             placeholder="Average Order Value *"
// //           />
// //           <input
// //             type="text"
// //             name="totalRegistrations"
// //             value={metrics.totalRegistrations}
// //             onChange={handleMetricsChange}
// //             placeholder="Total Registrations *"
// //           />
// //         </div>
// //         <div className="button-container">
// //           <button className="back-button" onClick={() => navigate(-1)}>Back</button>
// //           <button className="plan-next-event-button" onClick={handleSubmitMetrics}>
// //             Plan Your Next Event
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default EventDetails;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
// import './EventDetails.css';

// ChartJS.register(Title, Tooltip, Legend, ArcElement);

// const EventDetails = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const userEmail = location.state?.userEmail;
//   const { eventName } = location.state || {};
//   const [onlineInvestments, setOnlineInvestments] = useState({});
//   const [offlineInvestments, setOfflineInvestments] = useState({});
//   const [metrics, setMetrics] = useState({
//     overallRevenue: '',
//     averageOrderValue: '',
//     totalRegistrations: ''
//   });

//   useEffect(() => {
//     if (eventName) {
//       fetchInvestmentonline(eventName);
//       fetchInvestmentoffline(eventName);
//     }
//   }, [eventName]);

//   const fetchInvestmentonline = async (eventName) => {
//     try {
//       const response = await axios.get(`http://localhost:5000/event_investment_online/${eventName}/${userEmail}`);
//       const onlineData = response.data[0]; // Assuming online data is the first object in the response array
//       const filteredOnlineData = {
//         digital_service: onlineData.digital_service,
//         digital_ad_spend: onlineData.digital_ad_spend,
//         sms: onlineData.sms,
//         referrals: onlineData.referrals,
//         partner_digital: onlineData.partner_digital,
//         internal_edms: onlineData.internal_edms,
//       };
//       setOnlineInvestments(filteredOnlineData);
//     } catch (error) {
//       console.error('Error fetching investments:', error);
//     }
//   };

//   const fetchInvestmentoffline = async (eventName) => {
//     try {
//       const response = await axios.get(`http://localhost:5000/event_investment_offline/${eventName}/${userEmail}`);
//       const offlineData = response.data[0]; // Assuming online data is the first object in the response array
//       const filteredOfflineData = {
//         posters: offlineData.posters,
//         radio: offlineData.radio,
//         flyers: offlineData.flyers,
//         print_ads: offlineData.print_ads,
//         bus_sides: offlineData.bus_sides,
//         taxi_backs: offlineData.taxi_backs,
//         direct_mail: offlineData.direct_mail,
//         pr: offlineData.pr,
//         billboard: offlineData.billboard,
//         newspaper_ad: offlineData.newspaper_ad,
//       };
//       setOfflineInvestments(filteredOfflineData);
//     } catch (error) {
//       console.error('Error fetching investments:', error);
//     }
//   };
  

//   const getPieData = (investments, title) => {
//     const labels = Object.keys(investments);
//     const data = Object.values(investments);
//     return {
//       labels,
//       datasets: [{
//         label: title,
//         data,
//         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
//       }]
//     };
//   };

//   const handleMetricsChange = (e) => {
//     const { name, value } = e.target;
//     setMetrics(prevMetrics => ({ ...prevMetrics, [name]: value }));
//   };

//   const handleSubmitMetrics = () => {
//     navigate('/eventlist',{
//         state: {
//             eventName,
//             userEmail,
//           }
//     });
//   };
  
//   const handleBack = () => {
//     navigate('/eventtable', {
//       state: {
//         eventName,
//         userEmail,
//         onlineInvestments,
//         offlineInvestments
//       }
//     });
//   };

//   const handleSave = async () => {
//     const postData = {
//         eventName,
//       overallRevenue: metrics.overallRevenue,
//       averageOrderValue: metrics.averageOrderValue,
//       totalRegistrations: metrics.totalRegistrations,
//     };

//     try {
//       const response = await axios.post(`http://localhost:5000/save-metrics/${userEmail}`, postData);
//       // Handle success, maybe show a success message or redirect
//       console.log('Metrics saved successfully:', response.data);
//     } catch (error) {
//       // Handle error, show an error message
//       console.error('Error saving metrics:', error);
//     }
//   };


//   return (
//     <div className="event-details">
//       <h1>Event Details: {eventName}</h1>
//       <h2>Budget Allocation Existing Campaign</h2>
//       <div className="chart-wrapper">
//         <div className="chart-container">
//           <h3>Online Platforms</h3>
//           <Pie data={getPieData(onlineInvestments, 'Online Platforms')} />
//         </div>
//         <div className="chart-container">
//           <h3>Offline Platforms</h3>
//           <Pie data={getPieData(offlineInvestments, 'Offline Platforms')} />
//         </div>
//       </div>
//       <div className="metrics-overview">
//         <h2>Metrics Overview</h2>
//         <p>Please fill in your actual event performance data</p>
//         <div className="metrics-inputs">
//           <input
//             type="text"
//             name="overallRevenue"
//             value={metrics.overallRevenue}
//             onChange={handleMetricsChange}
//             placeholder="Actual Overall Revenue *"
//           />
//           <input
//             type="text"
//             name="averageOrderValue"
//             value={metrics.averageOrderValue}
//             onChange={handleMetricsChange}
//             placeholder="Actual Average Order Value *"
//           />
//           <input
//             type="text"
//             name="totalRegistrations"
//             value={metrics.totalRegistrations}
//             onChange={handleMetricsChange}
//             placeholder="Actual Total Registrations *"
//           />
//         </div>
//         <button className="back-button" onClick={handleSave}>Save</button>
//       </div>
//       <div className="button-container">
//           <button className="back-button" onClick={handleBack}>Back</button>
//           <button className="back-button" onClick={handleSubmitMetrics}>
//             Plan Your Next Event
//           </button>
//         </div>
//     </div>
//   );
// };

// export default EventDetails;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './EventDetails.css';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const EventDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;
  const { eventName } = location.state || {};
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [metrics, setMetrics] = useState({
    overallRevenue: '',
    averageOrderValue: '',
    totalRegistrations: ''
  });
  const [metricsLoaded, setMetricsLoaded] = useState(false);

  useEffect(() => {
    if (eventName && userEmail) {
      fetchInvestmentonline(eventName);
      fetchInvestmentoffline(eventName);
      fetchMetrics(eventName, userEmail);
    }
  }, [eventName, userEmail]);

  const fetchMetrics = async (eventName, userEmail) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/get_metric/${eventName}`);
      if (response.data && Object.keys(response.data).length > 0) {
        setMetrics({
          overallRevenue: response.data[0]['overallrevenue'] || '',
          averageOrderValue: response.data[0]['averageordervalue'] || '',
          totalRegistrations: response.data[0]['totalregistrations'] || ''
        });
      }
      setMetricsLoaded(true);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetricsLoaded(true);
    }
  };

  const fetchInvestmentonline = async (eventName) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${eventName}`);
      const onlineData = response.data[0];
      const filteredOnlineData = {
        tiktok: onlineData.tiktok,
        facebook: onlineData.facebook,
        instagram: onlineData.instagram,
        linkedin: onlineData.linkedin,
        google_demand_gen: onlineData.google_demand_gen,
        google_pmax: onlineData.google_pmax,
        google_search:onlineData.google_search,
        google_display:onlineData.google_display,
        sms: onlineData.sms,
        referrals: onlineData.referrals,
        partner_digital: onlineData.partner_digital,
        internal_edms: onlineData.internal_edms,
        bing_search:onlineData.bing_search,
        influencers: onlineData.influencers,
      };
      setOnlineInvestments(filteredOnlineData);
    } catch (error) {
      console.error('Error fetching online investments:', error);
    }
  };

  const fetchInvestmentoffline = async (eventName) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${eventName}`);
      const offlineData = response.data[0];
      const filteredOfflineData = {
        posters: offlineData.posters,
        metro_radio: offlineData.metro_radio,
        regional_radio:offlineData.regional_radio,
        flyers: offlineData.flyers,
        print_ads: offlineData.print_ads,
        bus_sides: offlineData.bus_sides,
        taxi_backs: offlineData.taxi_backs,
        direct_mail: offlineData.direct_mail,
        pr: offlineData.pr,
        billboard: offlineData.billboard,
        newspaper_ad: offlineData.newspaper_ad,
        tv:offlineData.tv,
        newspapers:offlineData.newspapers,
        flags:offlineData.flags,
      };
      setOfflineInvestments(filteredOfflineData);
    } catch (error) {
      console.error('Error fetching offline investments:', error);
    }
  };

  // const getPieData = (investments, title) => {
  //   const labels = Object.keys(investments);
  //   const data = Object.values(investments);
  //   return {
  //     labels,
  //     datasets: [{
  //       label: title,
  //       data,
  //       backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#4BC0C0', '#E7E9ED', '#36A2EB', '#FF6384'],
  //     }]
  //   };
  // };

  const getPieData = (investments, title) => {
    const capitalizeWords = (str) => {
      return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
  
    const labels = Object.keys(investments)
      .filter(key => investments[key] > 0)
      .map(label => capitalizeWords(label.replace(/_/g, ' ')));// Remove underscores here
    const data = labels.map(label => investments[label.replace(/ /g, '_').toLowerCase()]);// Convert back to use underscore for data lookup
    const colorPalette = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
    ];
    return {
      labels,
      datasets: [{
        label: title,
        data,
        backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
      }],
    };
  };

  const handleMetricsChange = (e) => {
    const { name, value } = e.target;
    setMetrics(prevMetrics => ({ ...prevMetrics, [name]: value }));
  };

  const handleSubmitMetrics = () => {
    if (metrics.overallRevenue && metrics.averageOrderValue && metrics.totalRegistrations) {
      navigate('/eventlist', {
        state: {
          eventName,
          userEmail,
        }
      });
    } else {
      alert('Please fill in all metrics fields before proceeding.');
    }
  };

  const handleBack = () => {
    navigate('/eventtable', {
      state: {
        eventName,
        userEmail,
        onlineInvestments,
        offlineInvestments
      }
    });
  };

  const handleSave = async () => {
    const postData = {
      eventName,
      overallRevenue: metrics.overallRevenue,
      averageOrderValue: metrics.averageOrderValue,
      totalRegistrations: metrics.totalRegistrations,
    };

    try {
      const response = await axios.post(`https://budget-tool-hed-1.onrender.com/save-metrics/${eventName}`, postData);
      console.log('Metrics saved successfully:', response.data);
      alert('Metrics saved successfully!');
    } catch (error) {
      console.error('Error saving metrics:', error);
      alert('Error saving metrics. Please try again.');
    }
  };

  if (!metricsLoaded) {
    return <div>Loading metrics...</div>;
  }

  return (
    <div className="event-details">
      <h1>Event Details: {eventName}</h1>
      <h2>Budget Allocation Existing Campaign</h2>
      <div className="chart-wrapper">
        <div className="chart-container">
          <h3>Online Platforms</h3>
          <Pie data={getPieData(onlineInvestments, 'Online Platforms')} />
        </div>
        <div className="chart-container">
          <h3>Offline Platforms</h3>
          <Pie data={getPieData(offlineInvestments, 'Offline Platforms')} />
        </div>
      </div>
      <div className="metrics-overview">
        <h2>Metrics Overview</h2>
        <p>Please fill in your actual event performance data</p>
        <div className="metrics-inputs">
          <input
            type="text"
            name="overallRevenue"
            value={metrics.overallRevenue}
            onChange={handleMetricsChange}
            placeholder="Actual Overall Revenue *"
          />
          <input
            type="text"
            name="averageOrderValue"
            value={metrics.averageOrderValue}
            onChange={handleMetricsChange}
            placeholder="Actual Average Order Value *"
          />
          <input
            type="text"
            name="totalRegistrations"
            value={metrics.totalRegistrations}
            onChange={handleMetricsChange}
            placeholder="Actual Total Registrations *"
          />
        </div>
        <button className="save-button" onClick={handleSave}>Save</button>
      </div>
      <div className="button-container">
        <button className="back-button" onClick={handleBack}>Back</button>
        <button 
          className="next-button" 
          onClick={handleSubmitMetrics}
          disabled={!metrics.overallRevenue || !metrics.averageOrderValue || !metrics.totalRegistrations}
        >
          Plan Your Next Event
        </button>
      </div>
    </div>
  );
};

export default EventDetails;