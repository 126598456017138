import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css';
import logo from './Half-Eaten-Donut.png'; // Make sure to replace this with the correct path to your logo image.

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="privacy-container">
      <header className="privacy-header">
        <img src={logo} alt="Company Logo" className="privacy-logo" onClick={handleLogoClick} />
      </header>

      <main className="privacy-content">
        <h1>Privacy Policy</h1>
        <p><strong>Last updated: 9/26/2024</strong></p>
        <p>
          At Half Eaten Donut, we are committed to protecting the privacy of our users.
          This Privacy Policy explains how we collect, use, disclose, and safeguard
          your personal information when you use our marketing data software and services, in
          compliance with the Australian Privacy Act 1988 and the Australian Privacy Principles (APPs).
        </p>
        <p>By using our services, you agree to the terms of this Privacy Policy.</p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect personal information directly from you, automatically through your use of our software, 
          or from third parties. The types of information we collect include:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, business name, and other contact details.</li>
          <li><strong>Usage Data:</strong> Information about how you use our software, such as IP address, browser type, device information, and interaction data.</li>
          <li><strong>Marketing Data:</strong> Data related to marketing campaigns, analytics, customer interactions, and website activity.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>
          We may use your personal information for the following purposes:
        </p>
        <ul>
          <li>To provide, operate, and improve our software and services.</li>
          <li>To communicate with you about updates, offers, and important information related to our software.</li>
          <li>To personalize your experience and deliver targeted content or marketing.</li>
          <li>To analyze software usage and improve our software's functionality.</li>
          <li>To comply with legal and regulatory obligations.</li>
        </ul>

        <h2>3. Disclosure of Personal Information</h2>
        <p>
          We may disclose your personal information under the following circumstances:
        </p>
        <ul>
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers to assist with our operations.</li>
          <li><strong>Legal Obligations:</strong> We may disclose your information where required by law or to protect our legal rights.</li>
          <li><strong>Business Transfers:</strong> If our business is sold or merged, your information may be transferred as part of that transaction.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>
          We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure.
          Our security measures include encryption, firewalls, and access controls.
        </p>

        <h2>5. Data Retention</h2>
        <p>
          We retain your personal information for as long as it is necessary to provide our services or as required by law.
          When your information is no longer needed, we will take reasonable steps to destroy or de-identify it.
        </p>

        <h2>6. Access and Correction</h2>
        <p>
          You have the right to request access to the personal information we hold about you and request corrections if it is inaccurate, incomplete, or outdated.
          To make such a request, please contact us at support@halfeatendonut.com.
        </p>

        <h2>7. Marketing Communications</h2>
        <p>
          We may send you marketing communications about our software and services.
          You can opt out of receiving marketing emails at any time by clicking the "unsubscribe" link in the emails or contacting us at support@halfeatendonut.com.
        </p>

        <h2>8. International Data Transfers</h2>
        <p>
          Your personal information may be transferred outside Australia to our service providers.
          We ensure that these providers comply with privacy and data protection laws equivalent to those in Australia.
        </p>

        <h2>9. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar technologies to collect information about your use of our software.
          You can manage your cookie preferences through your browser settings.
        </p>

        <h2>10. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by updating the "Last updated" date at the top of this page.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or how we handle your personal information, please contact us at:
        </p>
        <p>
          <strong>Half Eaten Donut</strong><br/>
          44 Chelsea St, Redfern NSW 2016, Australia<br/>
          support@halfeatendonut.com
        </p>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
