// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import './Login.css';
// import videoFile from './New-Final (1).mp4'; // Adjust the path if necessary
// import logo from './Half-Eaten-Donut.png'; // Adjust the path if necessary
// import brandingImage from './Half-Eaten-Donut.png'; // Adjust the path if necessary
// import { useGlobalUserState } from '../../hooks/useGlobalUserState';


// function Login() {
//   const [isSignIn, setIsSignIn] = useState(true);
//   const [formData, setFormData] = useState({
//     fullName: '',
//     companyName: '',
//     email: '',
//     password: '',
//     reEnterPassword: '',
//   });
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//   const [, setUserEmail] = useGlobalUserState();

//   const handleToggle = () => {
//     setIsSignIn(!isSignIn);
//     setError('');
//     setFormData({
//       fullName: '',
//       companyName: '',
//       email: '',
//       password: '',
//       reEnterPassword: '',
//     });
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');

//     if (!isSignIn && formData.password !== formData.reEnterPassword) {
//       setError("Passwords don't match");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `https://budget-tool-hed-1.onrender.com/api/${isSignIn ? 'signin' : 'signup'}`,
//         formData
//       );

//       if (isSignIn) {
//         localStorage.setItem('userEmail', formData.email);
//         setUserEmail(formData.email);
//         navigate('/dashboard', { state: { userEmail: formData.email } });  // Navigate to dashboard after successful login
//       } else {
//         setFormData({
//           fullName: '',
//           companyName: '',
//           email: '',
//           password: '',
//           reEnterPassword: '',
//         });
//         setIsSignIn(true);  // Switch to sign in form after successful account creation
//       }
//     } catch (error) {
//       if (error.response) {
//         setError(error.response.data.message || 'An error occurred');
//       } else if (error.request) {
//         setError('No response received from server. Please try again.');
//       } else {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   return (
//     <div className="main-container">
//        <div className="branding-wrapper">
//         {/* Replace the branding text with an image */}
//         <img src={brandingImage} alt="Half Eaten Donut Branding" className="branding-image" />
//         <p className="branding-subtitle">BudgetX - Your next event performance monitoring tool</p>
//         <p className="branding-footer">Track your event spending with BudgetX and analyze performance effortlessly. Maximize ROI with detailed post-event insights, all in one platform.</p>
//         <div className="video-section">
//           <video className="video-thumbnail" autoPlay loop muted>
//             <source src={videoFile} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       </div>
//       <div className="form-wrapper">
//         <div className="logo-container">
//           <img src={logo} alt="Half Eaten Donut Logo" className="logo" />
//         </div>
//         <div className="form-header">
//           <button
//             className={isSignIn ? 'header-button active-button' : 'header-button'}
//             onClick={() => setIsSignIn(true)}
//           >
//             Sign In
//           </button>
//           <button
//             className={!isSignIn ? 'header-button active-button' : 'header-button'}
//             onClick={() => setIsSignIn(false)}
//           >
//             Create Account
//           </button>
//         </div>
//         {error && <p className="error-message">{error}</p>}
//         {isSignIn ? (
//           <form className="signin-form" onSubmit={handleSubmit}>
//             <input
//               type="email"
//               name="email"
//               placeholder="Email Address"
//               required
//               className="input-text"
//               value={formData.email}
//               onChange={handleInputChange}
//             />
//             <input
//               type="password"
//               name="password"
//               placeholder="Password"
//               required
//               className="input-text"
//               value={formData.password}
//               onChange={handleInputChange}
//             />
//             <button type="submit" className="form-submit-button">Sign In</button>
//             <p className="form-toggle-text">
//               Don't have an account?{' '}
//               <span className="toggle-link-text" onClick={handleToggle}>
//                 Create Account
//               </span>
//             </p>
//           </form>
//         ) : (
//           <form className="signup-form" onSubmit={handleSubmit}>
//             <input
//               type="text"
//               name="fullName"
//               placeholder="Full Name"
//               required
//               className="input-text"
//               value={formData.fullName}
//               onChange={handleInputChange}
//             />
//             <input
//               type="text"
//               name="companyName"
//               placeholder="Company Name"
//               required
//               className="input-text"
//               value={formData.companyName}
//               onChange={handleInputChange}
//             />
//             <input
//               type="email"
//               name="email"
//               placeholder="Email Address"
//               required
//               className="input-text"
//               value={formData.email}
//               onChange={handleInputChange}
//             />
//             <input
//               type="password"
//               name="password"
//               placeholder="Password"
//               required
//               className="input-text"
//               value={formData.password}
//               onChange={handleInputChange}
//             />
//             <input
//               type="password"
//               name="reEnterPassword"
//               placeholder="Re-Enter Password"
//               required
//               className="input-text"
//               value={formData.reEnterPassword}
//               onChange={handleInputChange}
//             />
//             <button type="submit" className="form-submit-button">Create Account</button>
//             <p className="form-toggle-text">
//               Already have an account?{' '}
//               <span className="toggle-link-text" onClick={handleToggle}>
//                 Log in
//               </span>
//             </p>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import videoFile from './New-Final (1).mp4';
import logo from './Half-Eaten-Donut.png';
import brandingImage from './Half-Eaten-Donut.png';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';

function Login() {
  const [isSignIn, setIsSignIn] = useState(true);
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    email: '',
    password: '',
    reEnterPassword: '',
    acceptTerms: false,
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [, setUserEmail] = useGlobalUserState();

  const handleToggle = () => {
    setIsSignIn(!isSignIn);
    setError('');
    setFormData({
      fullName: '',
      companyName: '',
      email: '',
      password: '',
      reEnterPassword: '',
      acceptTerms: false,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!isSignIn) {
      if (formData.password !== formData.reEnterPassword) {
        setError("Passwords don't match");
        return;
      }
      if (!formData.acceptTerms) {
        setError("Please accept the terms and conditions");
        return;
      }
    }

    try {
      const response = await axios.post(
        `https://budget-tool-hed-1.onrender.com/api/${isSignIn ? 'signin' : 'signup'}`,
        formData
      );

      if (isSignIn) {
        localStorage.setItem('userEmail', formData.email);
        setUserEmail(formData.email);
        navigate('/eventlist', { state: { userEmail: formData.email } });
      } else {
        setFormData({
          fullName: '',
          companyName: '',
          email: '',
          password: '',
          reEnterPassword: '',
          acceptTerms: false,
        });
        setIsSignIn(true);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred');
      } else if (error.request) {
        setError('No response received from server. Please try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="main-container">
      <div className="branding-wrapper">
        <img src={brandingImage} alt="Half Eaten Donut Branding" className="branding-image" />
        <p className="branding-subtitle">BudgetX - Your next event performance monitoring tool</p>
         <p className="branding-footer">Track your event spending with BudgetX and analyze performance effortlessly. Maximize ROI with detailed post-event insights, all in one platform.</p>
         <div className="video-section">
          <video className="video-thumbnail" autoPlay loop muted>
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="logo-container">
          <img src={logo} alt="Half Eaten Donut Logo" className="logo" />
        </div>
        <div className="form-header">
          <button
            className={isSignIn ? 'header-button active-button' : 'header-button'}
            onClick={() => setIsSignIn(true)}
          >
            Sign In
          </button>
          <button
            className={!isSignIn ? 'header-button active-button' : 'header-button'}
            onClick={() => setIsSignIn(false)}
          >
            Create Account
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {isSignIn ? (
          <form className="signin-form" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              required
              className="input-text"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              className="input-text"
              value={formData.password}
              onChange={handleInputChange}
            />
            <button type="submit" className="form-submit-button">Sign In</button>
            <p className="form-toggle-text">
              Don't have an account?{' '}
              <span className="toggle-link-text" onClick={handleToggle}>
                Create Account
              </span>
            </p>
          </form>
        ) : (
          <form className="signup-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              required
              className="input-text"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="companyName"
              placeholder="Company Name"
              required
              className="input-text"
              value={formData.companyName}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              required
              className="input-text"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              className="input-text"
              value={formData.password}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="reEnterPassword"
              placeholder="Re-Enter Password"
              required
              className="input-text"
              value={formData.reEnterPassword}
              onChange={handleInputChange}
            />
            <div className="terms-checkbox">
              <input
                type="checkbox"
                name="acceptTerms"
                id="acceptTerms"
                checked={formData.acceptTerms}
                onChange={handleInputChange}
              />
              <label htmlFor="acceptTerms">
                I accept your{' '}
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                {' '}and{' '}
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>
              </label>
            </div>
            <button 
              type="submit" 
              className="form-submit-button"
              disabled={!formData.acceptTerms}
            >
              Create Account
            </button>
            <p className="form-toggle-text">
              Already have an account?{' '}
              <span className="toggle-link-text" onClick={handleToggle}>
                Log in
              </span>
            </p>
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
